<section class="mx-auto max-w-7xl pt-4">
  <div class="grid sm:grid-cols-3 gap-4">
    <div class="min-h-72 sm:col-span-2 relative"
    [ngClass]="(blok.order ? 'order-first' : 'order-last')">
      <div class="absolute inset-0">
        <picture>
          <source type="image/webp" [srcset]="blok.image.filename | transformImg : 'fit-in/0x600/smart/filters:format(webp)'">
          <source type="image/png" [srcset]="blok.image.filename | transformImg : 'fit-in/0x600/smart/filters:format(png)'">
          <img loading="lazy" class="h-full w-full object-cover" [src]="blok.image.filename | transformImg : 'fit-in/0x600/smart'" [alt]="blok.image.alt">
        </picture>
      </div>
    </div>
    <div class="min-h-80 flex flex-col justify-between items-start p-4" [ngClass]="blok?.color ? 'bg-' + blok.color : 'bg-fr-medium-orange'">
      <h4 class="text-4xl font-display font-semibold text-white pb-12">{{ blok.title }}</h4>
      <div class="flex flex-col space-y-4 items-start">
        <div class="prose font-body text-white" [innerHtml]="blok.text !== null ? resolveRichText(blok.text) : ''"></div>
        <button [routerLink]="'/' + blok.button_link.cached_url" *ngIf="blok.button_text" class="bg-white leading-6 tracking-wide font-body px-8 py-2 font-medium" [ngClass]="blok?.color ? 'text-' + blok.color : 'text-fr-medium-orange'">
          {{ blok.button_text }}
        </button>
      </div>
    </div>
  </div>
</section>