<div class="relative bg-white overflow-hidden h-full">
  <div class="mx-auto max-w-7xl relative px-4 sm:px-6 lg:px-0 z-10">
    <div [ngClass]="blok.number_of_cols ? 'md:grid-cols-' + blok.number_of_cols : 'md:grid-cols-3'" class="grid grid-cols-1 sm:grid-cols-2 gap-12 py-10">
      <div *ngFor="let story of stories" class="flex flex-col w-full items-start mx-auto overflow-hidden h-full pb-4 space-y-3 bg-white rounded-none shadow-lg">
        <ng-container *ngIf="story?.content?.featured_image?.filename; else noImage">
          <div [ngClass]="blok.number_of_cols == 2 ? 'h-64' : 'h-40'" class="relative w-full overflow-hidden">
            <picture>
              <source type="image/webp" [srcset]="story?.content?.featured_image.filename | transformImg : 'fit-in/0x600/smart/filters:format(webp)'">
              <source type="image/png" [srcset]="story?.content?.featured_image.filename | transformImg : 'fit-in/0x600/smart/filters:format(png)'">
              <img loading="lazy" class="h-full w-full object-cover absolute inset-0 object-center" [src]="story?.content?.featured_image.filename | transformImg : 'fit-in/0x600/smart'" [alt]="story?.content?.featured_image.alt">
            </picture>
          </div>
        </ng-container>
        <ng-template #noImage>
          <div [ngClass]="blok.number_of_cols == 2 ? 'h-64' : 'h-40'"  class="relative overflow-hidden">
            <div class="w-screen h-screen origin-center animate-background-spin">
              <div class="w-screen h-screen transform scale-x-150 scale-y-300 bg-gradient-to-r from-fr-soft-blue via-fr-medium-orange to-fr-soft-violett"></div>
            </div>
          </div>
        </ng-template>
        <div class="p-4 flex w-full flex-1 flex-col items-center justify-start space-y-3">
          <div class="flex-1">
            <span class="font-display text-xl text-center font-normal leading-7 line-clamp-2">
              {{ story.name }}
            </span>
            <span class="text-base font-body text-center line-clamp-3">
              {{ story?.content?.subtitle }}
            </span>
          </div>
          <div class="flex flex-row space-x-2 pt-2">
            <a [routerLink]="'/' + slug + '/' + story?.slug" class="mt-6 lg:mt-0 inline-flex items-center justify-center px-4 py-1.5 border-2 border-blue-500 border-transparent text-xs font-medium rounded-none text-white bg-fr-strong-dark-blue hover:bg-fr-strong-light-blue">Mehr erfahren</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>