<section class="mx-auto max-w-7xl pt-4" *ngIf="blok.display">
  <div class="grid sm:grid-cols-3 gap-4">
    <div class="min-h-72 sm:col-span-2 relative"
    [ngClass]="(blok.order ? 'order-first' : 'order-last')">
      <div class="absolute inset-0" *ngIf="blok?.image?.filename">
        <picture>
          <source type="image/webp" [srcset]="blok.image.filename | transformImg : 'fit-in/0x600/smart/filters:format(webp)'">
          <source type="image/png" [srcset]="blok.image.filename | transformImg : 'fit-in/0x600/smart/filters:format(png)'">
          <img loading="lazy" class="h-full w-full object-cover" [src]="blok.image.filename | transformImg : 'fit-in/0x600/smart'" [alt]="blok.image.alt">
        </picture>
      </div>
    </div>
    <div class="min-h-80 flex flex-col justify-between items-start p-4" *ngIf="upcomingMeeting">
      <h4 class="text-xl font-display font-semibold text-gray-800 pb-2">{{ upcomingMeeting?.topic }}</h4>
      <p *ngIf="upcomingMeeting?.agenda" class="text-lg pb-4 text-gray-600 line-clamp-4">{{ upcomingMeeting?.agenda }}</p>
      <span class="text-xl font-body text-fr-orange">{{ upcomingMeeting?.start_time?.toLocaleString() | date : 'dd.MM.yyyy HH:mm' }}</span>
      <div class="flex flex-col space-y-4 items-start">
        <div class="prose font-body text-white" [innerHtml]="blok.text !== null ? resolveRichText(blok.text) : ''"></div>
        <a [href]="blok?.typeformId" target="_blank" class="cursor-pointer mt-3 lg:mt-0 inline-flex items-center justify-center px-4 py-2 border-2 border-blue-500 border-transparent text-lg font-medium rounded-none text-white bg-fr-strong-dark-blue hover:bg-fr-strong-light-blue">
          Anmelden
        </a>
      </div>
    </div>
  </div>
</section>