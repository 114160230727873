<div class="relative">
  <div class="max-w-7xl mx-auto pt-32 pb-8 px-4 sm:pt-72 sm:pb-12 sm:px-6 relative overflow-hidden">
    <div class="absolute inset-0" *ngIf="blok?.background_image?.filename; else noBackgroundImage">
      <picture>
        <source type="image/webp" [srcset]="blok.background_image.focus ? (blok.background_image.filename | transformImg : '1200x330/filters:focal(' + blok.background_image.focus + '):format(webp)') :  blok.background_image.filename | transformImg:'1200x330/smart/filters:format(webp)'">
        <source type="image/png" [srcset]="blok.background_image.focus ? (blok.background_image.filename | transformImg : '1200x330/filters:focal(' + blok.background_image.focus + '):format(png)') :  blok.background_image.filename | transformImg:'1200x330/smart/filters:format(png)'">
        <img loading="lazy" [src]="blok.background_image.focus ? (blok.background_image.filename | transformImg : '1200x330/filters:focal(' + blok.background_image.focus + ')') :  blok.background_image.filename | transformImg:'1200x330/smart' " [alt]="blok.background_image.alt" class="w-full h-full object-cover">
      </picture>
     </div>
    <ng-template #noBackgroundImage>
      <div class="absolute inset-0 opacity-50">
        <div class="w-screen h-screen origin-center animate-background-spin">
          <div class="w-screen h-screen transform scale-x-150 scale-y-300 bg-gradient-to-r from-fr-strong-dark-blue via-fr-orange to-fr-soft-violett"></div>
        </div>
      </div>
    </ng-template>
    <div class="text-left z-10 relative">
      <h1 class="sm:w-2/3 font-display text-4xl tracking-tight font-semibold text-white sm:text-5xl">
        {{ blok.title }}
      </h1>
    </div>
    <div class="absolute -bottom-4 right-0">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-24 sm:w-56" viewBox="0 0 386.693 464.408">
        <g id="Layer_2" data-name="Layer 2" transform="translate(0.007 0.01)">
          <g id="Layer_1" data-name="Layer 1" transform="translate(-0.007 -0.01)">
            <path id="Pfad_1" data-name="Pfad 1" d="M148.213,464.4c-42.4,0-77.662-12.931-105.091-38.478C13.774,398.8-.724,363.225.02,320.868c1.411-59.207,31.347-105.8,89.457-139.181a126.881,126.881,0,0,1-14.3-17.515c-15.2-20.846-22.923-41.3-22.923-60.813,0-32.679,10.54-58.776,31.347-77.584l.509-.431A108.382,108.382,0,0,1,156.756.031c29.78,0,54.27,9.012,72.764,26.763s28.252,42.436,28.252,72.568c0,54.113-32.366,95.138-96.2,121.979-33.15,14.185-57.13,29-71.119,43.964C72.706,283.682,64.2,301.746,64.2,320.789c0,24.059,7.21,43.376,22.021,59.324s33.541,22.57,56.856,22.57a109.715,109.715,0,0,0,58.58-17.123l.392-.235A121.469,121.469,0,0,0,250.8,334.778l.2-.353c11.755-21.865,17.75-51.292,17.75-87.458V224.28H331.79v22.883c-.353,42.24-8.033,79.23-22.883,110.185l77.78,96.275h-81.7l-34.913-42.436C234.535,446.726,193.667,464.4,148.213,464.4Zm32.562-334.865c9.4-9.678,13.989-19.592,13.989-30.172,0-16.065-5.917-23.51-10.384-27.7a41.77,41.77,0,0,0-29.9-10.305,38.87,38.87,0,0,0-27.664,9.757A30.407,30.407,0,0,0,116.984,95.4a51.409,51.409,0,0,0,11.481,30.8c10.854,15.164,20.689,16.888,27.115,16.888,13.793-.47,25.2-13.558,25.2-13.558Z" transform="translate(0.007 0.01)" fill="#fff"/>
            <path id="Pfad_2" data-name="Pfad 2" d="M147.318,462.346c-42.162,0-77.31-12.852-104.386-38.165C13.78,397.3-.561,362.231.34,320.03c1.371-58.776,31.347-105.091,88.869-138.2l1.293-.744-1.058-1.1a130.524,130.524,0,0,1-14.185-17.358c-15.047-20.65-22.687-40.869-22.687-60.147,0-32.327,10.384-58.149,30.916-76.722l.47-.431a107.52,107.52,0,0,1,71.9-25c29.466,0,53.682,8.895,71.941,26.449s27.9,41.887,27.9,71.706c0,53.564-32.131,94.276-95.491,120.921-33.306,14.224-57.4,29.114-71.51,44.239-17.868,18.495-26.567,36.911-26.567,56.268,0,24.333,7.327,43.964,22.374,59.99,15.2,15.438,34.09,22.923,57.718,22.923a110.811,110.811,0,0,0,59.168-17.28l.392-.235A122.645,122.645,0,0,0,250.8,334.372v-.353c11.755-22.06,17.868-51.644,17.868-88.007V224.579h61.048l-.2,21.708c-.353,42.005-7.837,78.877-22.766,109.715l-.313.666,76.683,94.9H304.759l-35.265-43.1-.9.9C233.092,444.556,192.38,462.346,147.318,462.346ZM153.588,59.3a39.85,39.85,0,0,0-28.447,10.07,31.347,31.347,0,0,0-10.227,25.156,52.467,52.467,0,0,0,11.755,31.347c11.167,15.674,21.355,17.358,28.056,17.358,14.185-.509,25.587-13.44,26.1-13.989,9.6-9.835,14.263-19.984,14.263-30.916,0-16.536-6.152-24.216-10.776-28.565A43.1,43.1,0,0,0,153.588,59.3Z" transform="translate(0.902 0.887)" fill="#fff"/>
          </g>
        </g>
      </svg>      
    </div>
  </div>
</div>