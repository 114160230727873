<section class="flex flex-col mt-4 mx-auto max-w-7xl px-4 sm:px-6">
  <div class="grid sm:grid-cols-3 gap-4 -mx-4 sm:-mx-6">
    <div [routerLink]="'/' + card?.link?.cached_url" class="cursor-pointer h-72 bg-yellow-200 relative" [ngClass]="card.cols ? 'sm:col-span-' + card.cols : 'sm:col-span-1'" *ngFor="let card of blok.cards">
      <div class="absolute inset-0">
        <picture>
          <source type="image/webp" [srcset]="card.background_image?.filename | transformImg : 'fit-in/0x600/smart/filters:format(webp)'">
          <source type="image/png" [srcset]="card.background_image?.filename | transformImg : 'fit-in/0x600/smart/filters:format(png)'">
          <img loading="lazy" class="h-full w-full object-cover" [src]="card.background_image?.filename | transformImg : 'fit-in/0x600/smart'" [alt]="card.background_image?.alt">
        </picture>
      </div>
      <div class="absolute bottom-5 left-5 max-w-1/2">
        <h4 class="font-normal text-base text-white font-body filter drop-shadow-xl">{{ card.subtitle }}</h4>
        <h3 class="font-bold text-2xl uppercase text-white font-display filter drop-shadow-xl">{{ card.title }}</h3>
      </div>
    </div>
  </div>
</section>