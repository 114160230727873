<section class="mx-auto max-w-7xl py-12">
  <div class="grid sm:grid-cols-2 gap-4">
    <div class="flex flex-col space-y-2 items-center justify-center"
    [ngClass]="(blok.order ? 'order-first' : 'order-last')">
      <picture>
        <source type="image/webp" [srcset]="blok.image.filename | transformImg : 'fit-in/0x600/smart/filters:format(webp)'">
        <source type="image/png" [srcset]="blok.image.filename | transformImg : 'fit-in/0x600/smart/filters:format(png)'">
        <img loading="lazy" [src]="blok.image.filename | transformImg : 'fit-in/0x600/smart'" [alt]="blok.image.alt">
      </picture>
      <div class="flex flex-row items-center justify-start space-x-2 font-body">
        <span>{{ blok.meta }}</span>
      </div>
      <span class="text-xs font-body">{{ blok.isbn }}</span>
    </div>
    <div class="flex flex-col justify-start items-start p-4">
      <h4 class="text-xl font-display font-semibold text-fr-strong-light-blue pb-4">{{ blok.title }}</h4>
      <div class="flex flex-col space-y-4 items-start">
        <div class="prose font-body text-gray-800" [innerHtml]="blok.description"></div>
        <div class="flex flex-row items-start justify-start space-x-2 font-body">
          <span class="text-xl">{{ blok.price }}</span>
        </div>
        <a [href]="blok.stripe_link" target="_blank" *ngIf="blok.stripe_link" class="bg-fr-strong-light-blue leading-6 tracking-wide font-body px-8 py-2 font-normal text-white">
          {{ blok.stripe_button_text }}
        </a>
      </div>
    </div>
  </div>
</section>