<div class="relative bg-white overflow-hidden py-10">
  <div class="mx-auto max-w-7xl relative px-4 sm:px-6 lg:px-8 z-10">
    <h3 class="text-2xl sm:text-4xl font-display text-center pt-6">{{ blok.title  }}</h3>
    <p class="pt-4 pb-8 font-body text-lg text-center">{{ blok.subtitle }}</p>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12 py-10" *ngIf="upcomingMeeting?.occurrences">
      <div *ngFor="let story of upcomingMeeting?.occurrences">
        <div class="max-w-xl flex flex-col items-center justify-start mx-auto overflow-hidden pb-3 space-y-3 bg-white rounded-none shadow-lg">
          <div class="h-3 w-full bg-fr-orange">
          </div>
          <div class="py-4 px-6 flex flex-col items-start justify-center space-y-3">
            <span class="font-display text-lg text-left font-normal leading-7 line-clamp-4">
              {{ upcomingMeeting.topic }}
            </span>
            <span class="text-2xl text-fr-strong-dark-blue font-body text-center line-clamp-3">
              {{ story?.start_time.toLocaleString() | date : 'dd.MM.yyyy HH:mm' }} Uhr
            </span>
            <div class="flex flex-row space-x-2">
              <button (click)="startTypeform(upcomingMeeting.id, story.occurrence_id)" class="cursor-pointer mt-3 lg:mt-0 inline-flex items-center justify-center px-6 py-3 border-2 border-blue-500 border-transparent text-lg font-medium rounded-none text-white bg-fr-strong-dark-blue hover:bg-fr-strong-light-blue">Jetzt anmelden</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>