import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html'
})
export class VideoComponent implements OnInit {
  @Input() videoId: string = '';
  consentGranted = false;

  constructor() { }

  ngOnInit() {

    if (window && window.Cookiebot) {
      if (window.Cookiebot.hasResponse && window.Cookiebot.consent.marketing) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(tag);

        this.consentGranted = true;
      }
    }
  }

}
