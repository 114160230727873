<section class="mx-auto max-w-7xl px-4 sm:px-6 pb-8">
  <div class="flex flex-col items-center justify-center relative">
    <div class="w-2/3 py-24 sm:py-64 space-y-6 flex flex-col justify-center items-center relative z-10">
      <h1 class="font-display text-2xl bg-fr-orange px-2.5 py-1 sm:text-4xl text-center leading-6 text-white filter drop-shadow-2xl">{{ blok.title }}</h1>
      <button (click)="startTypeform()" class="bg-white px-12 py-4 text-xl font-body text-fr-orange font-normal hover:bg-yellow-50">{{ blok.button_text }}</button>
    </div>
    <div class="absolute inset-0 -mx-4 sm:-mx-6 overflow-hidden">
      <picture>
        <source type="image/webp" [srcset]="blok.background_image?.filename | transformImg : 'fit-in/0x700/smart/filters:format(webp)'">
        <source type="image/png" [srcset]="blok.background_image?.filename | transformImg : 'fit-in/0x700/smart/filters:format(png)'">
        <img loading="lazy" class="w-full h-full object-cover" [src]="blok.background_image?.filename | transformImg : 'fit-in/0x700/smart'" [alt]="blok.background_image?.alt">
      </picture>
    </div>
  </div>
</section>