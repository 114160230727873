<div class="aspect-w-16 aspect-h-9">
  <ng-container *ngIf="consentGranted">
    <youtube-player
      width="100%"
      height="100%" [videoId]="videoId">
    </youtube-player>
  </ng-container>

  <ng-container *ngIf="!consentGranted">
    <div class="w-full h-full bg-fr-light-orange flex flex-col justify-center items-center px-4 py-2">
      <span class="text-xl font-body text-white text-center">
        Bitte erlauben Sie die Nutzung von Marketing-Cookies, um dieses Video zu sehen.
      </span>
    </div>
  </ng-container>
</div>