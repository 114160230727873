<footer>
  <div class="grid sm:grid-cols-3 mx-auto max-w-7xl px-4 sm:px-6 pt-8 font-body tracking-wide text-sm">
    <div class="space-y-6 flex flex-col items-center sm:items-start py-4">
      <div class="flex flex-col" [innerHtml]="story.content?.address !== null ? resolveRichText(story.content?.address) : ''">
      </div>

      <div class="flex flex-col" [innerHtml]="story.content?.email !== null ? resolveRichText(story.content?.email) : ''">
      </div>
    </div>

    <div class="flex flex-row justify-center items-start py-4 order-first sm:order-none">
      <picture>
        <source type="image/webp" [srcset]="'https://a.storyblok.com/f/115325/4672x1205/3cf53cdf4b/fr-logo.png' | transformImg : 'fit-in/0x100/smart/filters:format(webp)'">
        <source type="image/png" [srcset]="'https://a.storyblok.com/f/115325/4672x1205/3cf53cdf4b/fr-logo.png' | transformImg : 'fit-in/0x100/smart/filters:format(png)'">
        <img loading="lazy" routerLink="/" class="cursor-pointer w-48 sm:w-64" [src]="'https://a.storyblok.com/f/115325/4672x1205/3cf53cdf4b/fr-logo.png' | transformImg : 'fit-in/0x100/smart'" alt="Freund und Reiter Logo">
      </picture>
    </div>

    <div class="space-y-6 flex flex-col sm:items-end items-center py-4">
      <div class="flex flex-col text-center sm:text-right" [innerHtml]="story.content?.claudia_text !== null ? resolveRichText(story.content?.claudia_text) : ''">
      </div>

      <div class="flex flex-col text-center sm:text-right" [innerHtml]="story.content?.wilfried_text !== null ? resolveRichText(story.content?.wilfried_text) : ''">
      </div>
    </div>
  </div>

  <div class="relative mx-auto max-w-7xl px-4 sm:px-6 py-8">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-300"></div>
    </div>
    <div class="relative flex justify-center">
      <div class="flex flex-row justify-center items-center w-full py-8 space-x-2">
        <a href="https://www.linkedin.com/company/freund-reiter/?originalSubdomain=at" target="_blank" class="h-8 w-8 bg-white">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8" viewBox="0 0 35 35">
            <path id="Pfad_3" data-name="Pfad 3" d="M337.256-59.406a17.5,17.5,0,0,0-17.5,17.5,17.5,17.5,0,0,0,17.5,17.5,17.5,17.5,0,0,0,17.5-17.5A17.5,17.5,0,0,0,337.256-59.406Zm-3.95,25.129h-3.388V-45.165h3.388Zm-1.694-12.375a1.964,1.964,0,0,1-1.965-1.963,1.964,1.964,0,0,1,1.965-1.962,1.962,1.962,0,0,1,1.962,1.962A1.962,1.962,0,0,1,331.613-46.652Zm14.371,12.375H342.6v-5.294c0-1.264-.025-2.887-1.759-2.887-1.762,0-2.03,1.375-2.03,2.795v5.386h-3.382V-45.165h3.246v1.489h.044a3.558,3.558,0,0,1,3.205-1.758c3.427,0,4.057,2.253,4.057,5.185Z" transform="translate(-319.757 59.406)"/>
          </svg>     
        </a> 

        <a href="https://www.xing.com/profile/Wilfried_Reiter" target="_blank" class="h-8 w-8 bg-black rounded-full flex flex-row items-center justify-center">
          <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="xing" class="w-4 text-white" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M162.7 210c-1.8 3.3-25.2 44.4-70.1 123.5-4.9 8.3-10.8 12.5-17.7 12.5H9.8c-7.7 0-12.1-7.5-8.5-14.4l69-121.3c.2 0 .2-.1 0-.3l-43.9-75.6c-4.3-7.8.3-14.1 8.5-14.1H100c7.3 0 13.3 4.1 18 12.2l44.7 77.5zM382.6 46.1l-144 253v.3L330.2 466c3.9 7.1.2 14.1-8.5 14.1h-65.2c-7.6 0-13.6-4-18-12.2l-92.4-168.5c3.3-5.8 51.5-90.8 144.8-255.2 4.6-8.1 10.4-12.2 17.5-12.2h65.7c8 0 12.3 6.7 8.5 14.1z"></path></svg>    
        </a> 
      </div>
    </div>
  </div>

  <div class="relative mx-auto max-w-7xl px-4 sm:px-6 pb-8">
    <div class="flex flex-row items-center justify-center text-sm divider divide-x divide-gray-500 tracking-wide text-gray-700 hover:text-gray-900 font-body" >
      <a [routerLink]="'/' + link.link?.cached_url" class="px-2" *ngFor="let link of story.content?.links">
        {{ link.title }}
      </a>
    </div>
  </div>

</footer>

<div class="hidden bg-fr-light-gray-blue bg-fr-medium-gray-blue bg-fr-soft-blue bg-fr-strong-light-blue bg-fr-strong-dark-blue bg-fr-light-orange bg-fr-medium-orange bg-fr-orange bg-fr-soft-light-red bg-fr-soft-medium-red bg-fr-soft-violett bg-fr-strong-violett text-fr-light-gray-blue text-fr-medium-gray-blue text-fr-soft-blue text-fr-strong-light-blue text-fr-strong-dark-blue text-fr-light-orange text-fr-medium-orange text-fr-orange text-fr-soft-light-red text-fr-soft-medium-red text-fr-soft-violett text-fr-strong-violett"></div>