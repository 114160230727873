import { Component, Input, OnInit } from '@angular/core';
import { StoryblokService } from 'src/app/storyblok.service';

@Component({
  selector: 'app-row-cards',
  templateUrl: './row-cards.component.html',
  styleUrls: ['./row-cards.component.scss']
})
export class RowCardsComponent implements OnInit {
  @Input() blok;

  constructor(private storyblokService: StoryblokService) { }

  ngOnInit(): void {
  }

}
