import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-tour',
  templateUrl: './google-tour.component.html',
  styleUrls: ['./google-tour.component.scss']
})
export class GoogleTourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
