// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBf0L6pNwKHJYBPrefog-7YlQrqk-jJQXM",
    authDomain: "freund-reiter-po9k.firebaseapp.com",
    projectId: "freund-reiter-po9k",
    storageBucket: "freund-reiter-po9k.appspot.com",
    messagingSenderId: "695298838074",
    appId: "1:695298838074:web:994767990000c203996f03",
    databaseURL: "https://freund-reiter-po9k.firebaseio.com",
  },
  url: 'https://www.freundundreiter.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
