import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private router: Router,
    private title: Title,
    private meta: Meta,
  ) { }

  generateTags({ title, description, og_title, og_description, og_image, twitter_title, twitter_description, twitter_image }) {
    this.title.setTitle(title);
    this.meta.updateTag({ property: 'description', content: description });

    // Open Graph
    this.meta.updateTag({ property: 'og:title', content: og_title });
    this.meta.updateTag({ property: 'og:url', content: `${environment.url}${this.router.url}` });
    this.meta.updateTag({ property: 'og:description', content: og_description });
    this.meta.updateTag({ property: 'og:image', content: og_image });
    this.meta.updateTag({ property: 'og:image:alt', content: og_title });
    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Kompetenzkompass Niederösterreich' });

    // Twitter Card
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:title', content: twitter_title });
    this.meta.updateTag({ name: 'twitter:description', content: twitter_description });
    this.meta.updateTag({ name: 'twitter:image', content: twitter_image });
  }
}