<section class="mx-auto max-w-7xl px-4 sm:px-6 pb-8">
  <div class="bg-yellow-50 flex flex-col items-center justify-center relative">
    <div class="w-2/3 py-24 sm:py-64 space-y-6 flex flex-col justify-center items-center relative z-10">
      <h1 class="font-display text-2xl sm:text-4xl text-center leading-6 text-white filter drop-shadow-2xl">{{ story.content?.title }}</h1>
      <div class="max-w-lg filter drop-shadow-2xl prose prose-2xl text-white prose-red text-center" [innerHtml]="story.content?.text_area ? resolveRichText(story.content?.text_area) : ''"></div>
      <button routerLink="/delphi-business-dialog" class="bg-white px-10 py-4 text-fr-orange font-bold text-xl hover:bg-yellow-50">{{ story.content?.button_text }}</button>
    </div>
    <div class="absolute inset-0 -mx-4 sm:-mx-6 overflow-hidden">
      <img [src]="story.content?.background_image?.filename" class="w-full h-full object-cover" alt="">
      <div class="absolute inset-0 opacity-50">
        <div class="w-full h-full origin-center animate-background-spin">
          <div class="w-full h-full transform scale-x-150 scale-y-300 bg-gradient-to-r from-fr-soft-blue via-fr-medium-orange to-fr-soft-violett"></div>
        </div>
      </div>
    </div>
  </div>
</section>