import { Component, Input, OnInit } from '@angular/core';
import { StoryblokService } from 'src/app/storyblok.service';
import * as typeformEmbed from '@typeform/embed';
import { Router } from '@angular/router';
import { GtagService } from 'src/app/gtag/gtag.service';

@Component({
  selector: 'app-typeform-hero',
  templateUrl: './typeform-hero.component.html',
  styleUrls: ['./typeform-hero.component.scss']
})
export class TypeformHeroComponent implements OnInit {
  @Input() blok;

  constructor(private storyblokService: StoryblokService, private router: Router, private gtag: GtagService) { }

  ngOnInit(): void {
  }

  resolveRichText(arg) {
    return this.storyblokService.resolveRichText(arg);
  }

  startTypeform() {
    this.gtag.event("started_duralog");
    const typeform = typeformEmbed.createPopup('jLqTReN5', {
      onSubmit: (event) => {
        this.gtag.event("finished_duralog");
        typeform.close();
        this.router.navigate(['/total-ownership/total-ownership-zum-kennenlernen']);
      }
    });
    typeform.open();
  }

}
