import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Components } from 'src/app/components/components';
import { SeoService } from 'src/app/seo.service';
import { StoryblokService } from 'src/app/storyblok.service';

@Component({
  selector: 'app-default-page',
  templateUrl: './default-page.component.html',
  styleUrls: ['./default-page.component.scss']
})
export class DefaultPageComponent implements OnInit {
  story = { content: null, name: '' };
  components = Components;
  slug: string;

  constructor(
    private route: ActivatedRoute,
    private storyblokService: StoryblokService,
    private seo: SeoService
  ) {
   }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      console.log(params);
      let slug = params.get('slug');
      let postId = params.get('postId');
      if (slug === null) {
        slug = '/home'
      }

      this.slug = slug;

      this._fetchBlog(postId ? (slug + '/' + postId) : slug);
    });
  }

  private async _fetchBlog(slug: string): Promise<void> {
    try {
      const { story } = await this.storyblokService.getStory(`${slug}`);
      this.story = story;
      if (this.story.content && this.story.content.SEO) {
        this.seo.generateTags({ title: this.story.content.SEO.title, description: this.story.content.SEO.description, og_title: this.story.content.SEO.og_title, og_description: this.story.content.SEO.og_description, og_image: this.story.content.SEO.og_image, twitter_title: this.story.content.SEO.twitter_title, twitter_description: this.story.content.SEO.twitter_description, twitter_image: this.story.content.SEO.twitter_image });
      }
    } catch (error) {
      console.error(error);
      // TODO: handle UI error
    }
  }

}
