import { Component, Input, OnInit } from '@angular/core';
import { StoryblokService } from 'src/app/storyblok.service';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit {
  @Input() blok;

  constructor(private storyblokService: StoryblokService) { }

  ngOnInit(): void {
  }

  resolveRichText(arg) {
    return this.storyblokService.resolveRichText(arg);
  }

}
