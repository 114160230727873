<section class="mx-auto max-w-7xl pt-4">
  <div class="grid sm:grid-cols-2 gap-4">
    <div class="sm:hidden flex flex-row align-center justify-center" [ngClass]="blok.order ? 'order-first' : 'order-last'">
      <div class="w-80 sm:w-auto sm:min-h-72 relative flex flex-col items-center justify-center">
        <ng-container *ngIf="blok.showAsGallery; else noGallery">
          <swiper
          class="max-w-full"
          [slidesPerView]="1"
          [spaceBetween]="50"
          [speed]="blok.gallery_speed ? blok.gallery_speed : 3500"
          [autoplay]="{
            delay: blok.gallery_delay ? blok.gallery_delay : 10000
          }"
          [pagination]="{ clickable: true }"
        >
          <ng-template swiperSlide *ngFor="let image of blok.images">
            <div class="mb-4">
              <picture>
                <source type="image/webp" [srcset]="image.filename | transformImg : 'fit-in/0x600/smart/filters:format(webp)'">
                <source type="image/png" [srcset]="image.filename | transformImg : 'fit-in/0x600/smart/filters:format(png)'">
                <img loading="lazy" class="w-full" [src]="image.filename | transformImg : 'fit-in/0x600/smart'" [alt]="image.alt">
              </picture>
            </div>
          </ng-template>
        </swiper>
        </ng-container>
        <ng-template #noGallery>
          <div class="flex flex-col items-center justify-center">
            <div *ngFor="let image of blok.images" class="mb-4">
              <picture>
                <source type="image/webp" [srcset]="image.filename | transformImg : 'fit-in/0x600/smart/filters:format(webp)'">
                <source type="image/png" [srcset]="image.filename | transformImg : 'fit-in/0x600/smart/filters:format(png)'">
                <img loading="lazy" [src]="image.filename | transformImg : 'fit-in/0x600/smart'" [alt]="image.alt">
              </picture>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="hidden w-80 sm:w-auto sm:min-h-72 relative sm:flex flex-col items-center justify-center" [ngClass]="blok.order ? 'order-first' : 'order-last'">
      <ng-container *ngIf="blok.showAsGallery; else noGallery">
        <swiper
        class="max-w-full"
        [slidesPerView]="1"
        [spaceBetween]="50"
        [speed]="blok.gallery_speed ? blok.gallery_speed : 3500"
        [autoplay]="{
          delay: blok.gallery_delay ? blok.gallery_delay : 10000
        }"
        [pagination]="{ clickable: true }"
      >
        <ng-template swiperSlide *ngFor="let image of blok.images">
          <div class="mb-4">
            <picture>
              <source type="image/webp" [srcset]="image.filename | transformImg : 'fit-in/0x600/smart/filters:format(webp)'">
              <source type="image/png" [srcset]="image.filename | transformImg : 'fit-in/0x600/smart/filters:format(png)'">
              <img loading="lazy" class="w-full" [src]="image.filename | transformImg : 'fit-in/0x600/smart'" [alt]="image.alt">
            </picture>
          </div>
        </ng-template>
      </swiper>
      </ng-container>
      <ng-template #noGallery>
        <div class="flex flex-col items-center justify-center">
          <div *ngFor="let image of blok.images" class="mb-4">
            <picture>
              <source type="image/webp" [srcset]="image.filename | transformImg : 'fit-in/0x600/smart/filters:format(webp)'">
              <source type="image/png" [srcset]="image.filename | transformImg : 'fit-in/0x600/smart/filters:format(png)'">
              <img loading="lazy" [src]="image.filename | transformImg : 'fit-in/0x600/smart'" [alt]="image.alt">
            </picture>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="min-h-72 flex flex-col justify-center items-start py-8" [ngClass]="blok.order ? 'px-16' : 'pr-16 pl-6 sm:pr-16'">
      <div class="prose font-body prose-lg leading-8" [innerHtml]="blok.text !== null ? resolveRichText(blok.text) : ''"></div>
    </div>
  </div>
</section>