<section class="flex flex-col space-y-8 mx-auto max-w-7xl px-4 sm:px-6 py-24">
  <div class="grid sm:grid-cols-3 gap-8">
    <div class="min-h-72 flex flex-col items-center justify-center">
      <div class="space-y-4 text-xs">
        <div class="text-center">
          <span class="uppercase font-bold">Freund & Reiter</span>
          <p>
            Bremengasse 4<br>
            2540 Bad Vöslau<br>
            office@freundundreiter.com
          </p>
        </div>

        <div class="text-center">
          <span class="uppercase font-bold">Mag. Claudia Freund</span>
          <p>
            +43 664 41 24 314
          </p>
        </div>

        <div class="text-center">
          <span class="uppercase font-bold">Mag. Wilfried Reiter</span>
          <p>
            +43 664 41 24 314
          </p>
        </div>

        <div class="flex flex-row justify-center">
          <a href="https://www.linkedin.com/company/freund-reiter/?originalSubdomain=at" target="_blank" class="inline-flex items-center border border-transparent rounded-full shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <!-- Heroicon name: solid/plus -->
            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" viewBox="0 0 35 35">
              <path id="Pfad_3" data-name="Pfad 3" d="M337.256-59.406a17.5,17.5,0,0,0-17.5,17.5,17.5,17.5,0,0,0,17.5,17.5,17.5,17.5,0,0,0,17.5-17.5A17.5,17.5,0,0,0,337.256-59.406Zm-3.95,25.129h-3.388V-45.165h3.388Zm-1.694-12.375a1.964,1.964,0,0,1-1.965-1.963,1.964,1.964,0,0,1,1.965-1.962,1.962,1.962,0,0,1,1.962,1.962A1.962,1.962,0,0,1,331.613-46.652Zm14.371,12.375H342.6v-5.294c0-1.264-.025-2.887-1.759-2.887-1.762,0-2.03,1.375-2.03,2.795v5.386h-3.382V-45.165h3.246v1.489h.044a3.558,3.558,0,0,1,3.205-1.758c3.427,0,4.057,2.253,4.057,5.185Z" transform="translate(-319.757 59.406)"/>
            </svg>     
          </a>
        </div>
      </div>
    </div>
    <div class="h-72 sm:col-span-2 bg-yellow-200 relative">
      <div class="absolute inset-0">
        <img class="h-full w-full object-cover" src="https://via.placeholder.com/300" alt="">
      </div>
    </div>
  </div>
</section>