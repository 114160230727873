import { Component, Input, OnInit } from '@angular/core';
import { StoryblokService } from 'src/app/storyblok.service';

@Component({
  selector: 'app-row-image-left',
  templateUrl: './row-image-left.component.html',
  styleUrls: ['./row-image-left.component.scss']
})
export class RowImageLeftComponent implements OnInit {
  @Input() blok;

  constructor(private storyblokService: StoryblokService) { }

  ngOnInit(): void {
    console.log(this.blok);
  }

  resolveRichText(arg) {
    return this.storyblokService.resolveRichText(arg);
  }

}
