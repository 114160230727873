import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as typeformEmbed from '@typeform/embed';
import { GtagService } from 'src/app/gtag/gtag.service';

declare var Calendly;
@Component({
  selector: 'app-cta-banner',
  templateUrl: './cta-banner.component.html',
  styleUrls: ['./cta-banner.component.scss']
})
export class CtaBannerComponent implements OnInit {
  @Input() blok;
  constructor(private router: Router, private gtag: GtagService) { }

  ngOnInit(): void {
    console.log(this.blok);
  }

  openCalendlyAppointmentModal(url) {
    this.gtag.event('opened_calendly_popup');
    Calendly.initPopupWidget({url: url});
    return false;
  }

  startTypeform(typeformId) {
    this.gtag.event(`started_duralog_with_id_${typeformId}`);
    const typeform = typeformEmbed.createPopup(typeformId, {
      onSubmit: (event) => {
        this.gtag.event(`finished_duralog_with_id_${typeformId}`);
        typeform.close();
        this.router.navigate(['/vielen-dank']);
      }
    });
    typeform.open();
  }

  startDuralog() {
    this.gtag.event("started_duralog");
    const typeform = typeformEmbed.createPopup('jLqTReN5', {
      onSubmit: (event) => {
        this.gtag.event("finished_duralog");
        typeform.close();
        this.router.navigate(['/total-ownership/total-ownership-zum-kennenlernen']);
      }
    });
    typeform.open();
  }

}
