import { Component, OnInit } from '@angular/core';
import { StoryblokService } from 'src/app/storyblok.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  story = { content: null, name: '' };
  isMenuExpanded = false;

  constructor(private storyblokService: StoryblokService) { }

  ngOnInit(): void {
    this._fetchStory();
  }

  private async _fetchStory(): Promise<void> {
    try {
      const { story } = await this.storyblokService.getStory(`global`);
      this.story = story;
      console.log(this.story);
    } catch (error) {
      console.error(error);
      // TODO: handle UI error
    }
  }

  toggleMenu(): void {
    this.isMenuExpanded = !this.isMenuExpanded;
  }
  
  resolveRichText(arg) {
    return this.storyblokService.resolveRichText(arg);
  }

}
