import { Component, OnInit } from '@angular/core';
import { StoryblokService } from 'src/app/storyblok.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  story = { content: null, name: '' };

  constructor(private storyblokService: StoryblokService) { }

  ngOnInit(): void {
    this._fetchStory();
  }

  private async _fetchStory(): Promise<void> {
    try {
      const { story } = await this.storyblokService.getStory(`global`);
      this.story = story;
    } catch (error) {
      console.error(error);
      // TODO: handle UI error
    }
  }

  resolveRichText(arg) {
    return this.storyblokService.resolveRichText(arg);
  }
}
