import { BlogHeroComponent } from "./blog-hero/blog-hero.component"
import { ContactSectionComponent } from "./contact-section/contact-section.component"
import { CookieInformationComponent } from "./cookie-information/cookie-information.component"
import { CtaBannerComponent } from "./cta-banner/cta-banner.component"
import { DefaultHeroComponent } from "./default-hero/default-hero.component"
import { EventSectionComponent } from "./event-section/event-section.component"
import { FolderOverviewComponent } from "./folder-overview/folder-overview.component"
import { GoogleTourComponent } from "./google-tour/google-tour.component"
import { ImageComparisonSliderComponent } from "./image-comparison-slider/image-comparison-slider.component"
import { ProductSectionComponent } from "./product-section/product-section.component"
import { RowCardsComponent } from "./row-cards/row-cards.component"
import { RowImageLeftComponent } from "./row-image-left/row-image-left.component"
import { RowImageTextComponent } from "./row-image-text/row-image-text.component"
import { ShareSectionComponent } from "./share-section/share-section.component"
import { TestimonialCarouselComponent } from "./testimonial-carousel/testimonial-carousel.component"
import { TextAreaComponent } from "./text-area/text-area.component"
import { TitleComponent } from "./title/title.component"
import { TypeformHeroComponent } from "./typeform-hero/typeform-hero.component"
import { UpcomingEventsGridComponent } from "./upcoming-events-grid/upcoming-events-grid.component"
import { YoutubeGridComponent } from "./youtube-grid/youtube-grid.component"

let Components = {
  'default_hero': DefaultHeroComponent,
  'cta_banner': CtaBannerComponent,
  'row_image': RowImageLeftComponent,
  'row_image_text': RowImageTextComponent,
  'row_cards': RowCardsComponent,
  'typeform_hero': TypeformHeroComponent,
  'testimonial_carousel': TestimonialCarouselComponent,
  'text-area': TextAreaComponent,
  'folder_overview': FolderOverviewComponent,
  'contact_section': ContactSectionComponent,
  'blog_hero': BlogHeroComponent,
  'product_section': ProductSectionComponent,
  'share_section': ShareSectionComponent,
  'upcoming_events_grid': UpcomingEventsGridComponent,
  'event_section': EventSectionComponent,
  'title': TitleComponent,
  'image_comparison_slider': ImageComparisonSliderComponent,
  'google-tour': GoogleTourComponent,
  'youtube-grid': YoutubeGridComponent,
  'cookie-information': CookieInformationComponent,
}

export { Components }
