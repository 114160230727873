import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultPageComponent } from './pages/default-page/default-page.component';
import { ErgebnisComponent } from './pages/ergebnis/ergebnis.component';
import { KontaktComponent } from './pages/kontakt/kontakt.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultPageComponent
  },
  {
    path: 'ergebnis',
    component: ErgebnisComponent
  },
  {
    path: 'ergebnis/:resId',
    component: ErgebnisComponent
  },
  {
    path: ':slug',
    component: DefaultPageComponent,
  },
  {
    path: ':slug/:postId',
    component: DefaultPageComponent,
  },
  {
    path: 'kontakt',
    component: KontaktComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
