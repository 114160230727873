import { Component, Input, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { StoryblokService } from 'src/app/storyblok.service';
import * as typeformEmbed from '@typeform/embed';
import { GtagService } from 'src/app/gtag/gtag.service';

@Component({
  selector: 'app-event-section',
  templateUrl: './event-section.component.html',
  styleUrls: ['./event-section.component.scss']
})
export class EventSectionComponent implements OnInit {
  @Input() blok;

  upcomingMeeting;
  events;

  constructor(private gtag: GtagService, private storyblokService: StoryblokService, private aff: AngularFireFunctions, private router: Router) { }

  ngOnInit(): void {
    this.getUpcomingMeetings();
  }

  resolveRichText(arg) {
    return this.storyblokService.resolveRichText(arg);
  }

  async getUpcomingMeetings() {
    if (this.blok.meetingId) {
      console.log(this.blok.meetingId);
      // this.upcomingMeetings = await this.aff.httpsCallable('getRecurringEvent')({
      //   meetingId: this.blok.meetingId
      // }).toPromise();

      this.upcomingMeeting = await this.aff.httpsCallable('getUpcomingEvent')({
        meetingId: this.blok.meetingId
      }).toPromise();

      console.log(this.upcomingMeeting);
    }
  }

  startTypeform(eventId) {
    this.gtag.event("started_delphi_signup");
    const typeform = typeformEmbed.createPopup(this.blok.typeformId, {
      hidden: { eventid: eventId, eventname: this.upcomingMeeting.topic },
      onSubmit: (event) => {
        this.gtag.event("finished_delphi_signup");
        typeform.close();
        this.router.navigate(['/anmeldebestaetigung']);
      }
    });
    typeform.open();
  }


}
