<section *ngIf="blok" class="mx-auto max-w-7xl px-4 sm:px-6 py-8 rounded-sm mt-4"
  [ngClass]="blok?.banner_color ? 'bg-' + blok.banner_color : 'bg-fr-medium-orange'">
  <div class="flex sm:flex-row justify-between items-center flex-col space-y-3 sm:space-y-0">
    <span class="font-body text-2xl font-normal text-white text-center sm:text-left">{{ blok.text }}</span>
    <ng-container *ngIf="blok.link_to_site === 'calendly'">
      <button
        [ngClass]="blok?.banner_color ? 'text-' + blok.banner_color : 'text-fr-medium-orange'"
        (click)="openCalendlyAppointmentModal(blok.calendly_url)"
        class="text-2xl font-body bg-white px-8 py-2 font-normal hover:bg-yellow-50">
        {{ blok.button_text }}
      </button>
    </ng-container>
    <ng-container *ngIf="blok.link_to_site === 'link'">
      <ng-container *ngIf="blok.button_link?.linktype === 'url'">
        <a
        [ngClass]="blok?.banner_color ? 'text-' + blok.banner_color : 'text-fr-medium-orange'"
        [href]="blok?.button_link?.cached_url"
        target="_blank"
        class="text-2xl font-body bg-white px-8 py-2 font-normal hover:bg-yellow-50">
        {{ blok.button_text }}
      </a>
      </ng-container>
      <ng-container *ngIf="blok.button_link?.linktype === 'story'">
        <button
          [ngClass]="blok?.banner_color ? 'text-' + blok.banner_color : 'text-fr-medium-orange'"
          [routerLink]="'/' + blok?.button_link?.cached_url"
          class="text-2xl font-body bg-white px-8 py-2 font-normal hover:bg-yellow-50">
          {{ blok.button_text }}
        </button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="blok.link_to_site === 'duralog'">
      <button
        [ngClass]="blok?.banner_color ? 'text-' + blok.banner_color : 'text-fr-medium-orange'"
        (click)="startDuralog()"
        class="text-2xl font-body bg-white px-8 py-2 font-normal hover:bg-yellow-50">
        {{ blok.button_text }}
      </button>
    </ng-container>
    <ng-container *ngIf="blok.link_to_site === 'form'">
      <button
        [ngClass]="blok?.banner_color ? 'text-' + blok.banner_color : 'text-fr-medium-orange'"
        (click)="startTypeform(blok.typeformId)"
        class="text-2xl font-body bg-white px-8 py-2 font-normal hover:bg-yellow-50">
        {{ blok.button_text }}
      </button>
    </ng-container>
    <ng-container *ngIf="blok.link_to_site !== 'form' && blok.link_to_site !== 'calendly' && blok.link_to_site !== 'link'">
      <button
        [ngClass]="blok?.banner_color ? 'text-' + blok.banner_color : 'text-fr-medium-orange'"
        [routerLink]="'/' + blok?.button_link?.cached_url"
        class="text-2xl font-body bg-white px-8 py-2 font-normal hover:bg-yellow-50">
        {{ blok.button_text }}
      </button>
    </ng-container>
  </div>
</section>