import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-cookie-information',
  templateUrl: './cookie-information.component.html'
})
export class CookieInformationComponent implements AfterViewInit {
  @ViewChild('cookieDeclaration') cookieDeclaration: ElementRef;
  constructor(
    private _renderer2: Renderer2
  ) { }

  ngAfterViewInit() {
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.text = ``;
    script.src = "https://consent.cookiebot.com/1c229630-2e3d-4967-ab15-a5c4f4ccbb23/cd.js"

    console.log(script);

    this._renderer2.appendChild(this.cookieDeclaration.nativeElement, script);
  }

}
