<section class="mx-auto max-w-7xl pt-4 sm:px-0 px-6">
  <div class="flex flex-row items-center justify-end pb-2" *ngIf="blok.text">
    <span class="text-xs font-body">{{ blok.text }}</span>
  </div>
  <div class="flex flex-row items-center justify-end space-x-2">
    <button *ngIf="blok.facebook" shareButton="facebook" type="button" class="inline-flex items-center p-1.5 border border-transparent rounded-none shadow-none text-white bg-fr-soft-medium-red hover:bg-fr-soft-light-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
      <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" class="h-5 w-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path></svg>
    </button>
    <button *ngIf="blok.xing" shareButton="xing" type="button" class="inline-flex items-center p-1.5 border border-transparent rounded-none shadow-none text-white bg-fr-soft-medium-red hover:bg-fr-soft-light-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
      <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="xing" class="h-5 w-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M162.7 210c-1.8 3.3-25.2 44.4-70.1 123.5-4.9 8.3-10.8 12.5-17.7 12.5H9.8c-7.7 0-12.1-7.5-8.5-14.4l69-121.3c.2 0 .2-.1 0-.3l-43.9-75.6c-4.3-7.8.3-14.1 8.5-14.1H100c7.3 0 13.3 4.1 18 12.2l44.7 77.5zM382.6 46.1l-144 253v.3L330.2 466c3.9 7.1.2 14.1-8.5 14.1h-65.2c-7.6 0-13.6-4-18-12.2l-92.4-168.5c3.3-5.8 51.5-90.8 144.8-255.2 4.6-8.1 10.4-12.2 17.5-12.2h65.7c8 0 12.3 6.7 8.5 14.1z"></path></svg>
    </button>
    <button *ngIf="blok.linkedIn" shareButton="linkedin" type="button" class="inline-flex items-center p-1.5 border border-transparent rounded-none shadow-none text-white bg-fr-soft-medium-red hover:bg-fr-soft-light-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
      <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" class="h-5 w-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
    </button>
  </div>
</section>