<div class="bg-white relative overflow-hidden">
  <div class="max-w-7xl mx-auto pt-12 pb-8 px-4 sm:pb-12 sm:px-6 relative">
    <div class="text-left">
      <h1 class="md:w-1/2 font-display text-xl tracking-tight font-semibold text-fr-orange sm:text-4xl">
        {{ blok.title }}
      </h1>
      <div class="flex flex-row items-start justify-start space-x-3 pt-6">
        <span class="text-base text-gray-800 font-bold font-body">{{ blok.author }}</span>
        <span class="text-base text-gray-800 font-bold font-body">{{ blok.date | date: 'dd.MM.yyyy' }}</span>
      </div>
    </div>
  </div>
</div>