import { Component, Input, OnInit } from '@angular/core';
import { StoryblokService } from 'src/app/storyblok.service';

// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  A11y,
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);

@Component({
  selector: 'app-row-image-text',
  templateUrl: './row-image-text.component.html',
  styleUrls: ['./row-image-text.component.scss']
})
export class RowImageTextComponent implements OnInit {
  @Input() blok;

  constructor(private storyblokService: StoryblokService) { }

  ngOnInit(): void {
  }

  resolveRichText(arg) {
    return this.storyblokService.resolveRichText(arg);
  }

}
