import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoryblokService } from 'src/app/storyblok.service';

@Component({
  selector: 'app-ergebnis',
  templateUrl: './ergebnis.component.html',
  styleUrls: ['./ergebnis.component.scss']
})
export class ErgebnisComponent implements OnInit {
  story = { content: null, name: '' };

  constructor(private storyblokService: StoryblokService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      console.log(params);
      let postId = params.get('resId');
      this._fetchBlog('ergebnis');
    });
  }

  private async _fetchBlog(slug: string): Promise<void> {
    try {
      const { story } = await this.storyblokService.getStory(`${slug}`);
      this.story = story;
      console.log(this.story);
    } catch (error) {
      console.error(error);
      // TODO: handle UI error
    }
  }

  resolveRichText(arg) {
    return this.storyblokService.resolveRichText(arg);
  }

}
