<section class="mx-auto max-w-7xl py-16">
  <div class="grid sm:grid-cols-3 gap-4">
    <div class="min-h-80 flex flex-col justify-between items-start p-4">
      <div class="flex flex-col space-y-4 items-start justify-center">
        <div class="prose font-body text-gray-800" [innerHtml]="blok.text !== null ? resolveRichText(blok.text) : ''"></div>
        <div class="flex flex-row justify-start items-center w-full py-8 space-x-2">
          <a href="https://www.linkedin.com/company/freund-reiter/?originalSubdomain=at" target="_blank" class="h-8 w-8 bg-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-8" viewBox="0 0 35 35">
              <path id="Pfad_3" data-name="Pfad 3" d="M337.256-59.406a17.5,17.5,0,0,0-17.5,17.5,17.5,17.5,0,0,0,17.5,17.5,17.5,17.5,0,0,0,17.5-17.5A17.5,17.5,0,0,0,337.256-59.406Zm-3.95,25.129h-3.388V-45.165h3.388Zm-1.694-12.375a1.964,1.964,0,0,1-1.965-1.963,1.964,1.964,0,0,1,1.965-1.962,1.962,1.962,0,0,1,1.962,1.962A1.962,1.962,0,0,1,331.613-46.652Zm14.371,12.375H342.6v-5.294c0-1.264-.025-2.887-1.759-2.887-1.762,0-2.03,1.375-2.03,2.795v5.386h-3.382V-45.165h3.246v1.489h.044a3.558,3.558,0,0,1,3.205-1.758c3.427,0,4.057,2.253,4.057,5.185Z" transform="translate(-319.757 59.406)"/>
            </svg>     
          </a> 

          <a href="https://www.xing.com/profile/Wilfried_Reiter" target="_blank" class="h-8 w-8 bg-black rounded-full flex flex-row items-center justify-center">
            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="xing" class="w-4 text-white" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M162.7 210c-1.8 3.3-25.2 44.4-70.1 123.5-4.9 8.3-10.8 12.5-17.7 12.5H9.8c-7.7 0-12.1-7.5-8.5-14.4l69-121.3c.2 0 .2-.1 0-.3l-43.9-75.6c-4.3-7.8.3-14.1 8.5-14.1H100c7.3 0 13.3 4.1 18 12.2l44.7 77.5zM382.6 46.1l-144 253v.3L330.2 466c3.9 7.1.2 14.1-8.5 14.1h-65.2c-7.6 0-13.6-4-18-12.2l-92.4-168.5c3.3-5.8 51.5-90.8 144.8-255.2 4.6-8.1 10.4-12.2 17.5-12.2h65.7c8 0 12.3 6.7 8.5 14.1z"></path></svg>    
          </a> 
        </div>
        <picture>
          <source type="image/webp" [srcset]="blok.logo.filename | transformImg : 'fit-in/0x200/smart/filters:format(webp)'">
          <source type="image/png" [srcset]="blok.logo.filename | transformImg : 'fit-in/0x200/smart/filters:format(png)'">
          <img loading="lazy" class="w-44" [src]="blok.logo.filename | transformImg : 'fit-in/0x200/smart'" [alt]="blok.logo.alt">
        </picture>
      </div>
    </div>
    <div class="min-h-72 sm:col-span-2 relative">
      <div class="absolute inset-0">
        <picture>
          <source type="image/webp" [srcset]="blok.image.filename | transformImg : 'fit-in/0x600/smart/filters:format(webp)'">
          <source type="image/png" [srcset]="blok.image.filename | transformImg : 'fit-in/0x600/smart/filters:format(png)'">
          <img loading="lazy" class="h-full w-full object-cover" [src]="blok.image.filename | transformImg : 'fit-in/0x600/smart'" [alt]="blok.image.alt">
        </picture>
      </div>
    </div>
  </div>
</section>