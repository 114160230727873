import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StoryblokService } from './storyblok.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  story = { content: null, name: '' };
  location: any;
  static isBrowser = new BehaviorSubject<boolean>(null);

  constructor(
    private storyblokService: StoryblokService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
    if (isPlatformBrowser(this.platformId)) {
      window.storyblok.init();
      window.storyblok.on(['change', 'published'], () => {
        location.reload();
      });
    }
  }

  ngOnInit(): void {
    this._fetchStory();
    if (isPlatformBrowser(this.platformId)) {
      this.location = location?.origin;
    }
  }

  private async _fetchStory(): Promise<void> {
    try {
      const { story } = await this.storyblokService.getStory(`global`);
      this.story = story;
    } catch (error) {
      console.error(error);
      // TODO: handle UI error
    }
  }
}
