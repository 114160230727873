import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultPageComponent } from './pages/default-page/default-page.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { HttpClientModule } from '@angular/common/http';
import { SwiperModule } from 'swiper/angular';
import { KontaktComponent } from './pages/kontakt/kontakt.component';
import { DefaultHeroComponent } from './components/default-hero/default-hero.component';
import { StoryblokDirective } from './storyblok.directive';
import { DynamicModule } from 'ng-dynamic-component';
import { CtaBannerComponent } from './components/cta-banner/cta-banner.component';
import { RowImageLeftComponent } from './components/row-image-left/row-image-left.component';
import { StoryblokService } from './storyblok.service';
import { RowImageTextComponent } from './components/row-image-text/row-image-text.component';
import { TypeformHeroComponent } from './components/typeform-hero/typeform-hero.component';
import { TestimonialCarouselComponent } from './components/testimonial-carousel/testimonial-carousel.component';
import { RowCardsComponent } from './components/row-cards/row-cards.component';
import { ImagePipe } from './transformImage.pipe';
import { ErgebnisComponent } from './pages/ergebnis/ergebnis.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { FolderOverviewComponent } from './components/folder-overview/folder-overview.component';
import { ContactSectionComponent } from './components/contact-section/contact-section.component';
import { BlogHeroComponent } from './components/blog-hero/blog-hero.component';
import { ProductSectionComponent } from './components/product-section/product-section.component';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareSectionComponent } from './components/share-section/share-section.component';
import { UpcomingEventsGridComponent } from './components/upcoming-events-grid/upcoming-events-grid.component';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { environment } from 'src/environments/environment';
import { EventSectionComponent } from './components/event-section/event-section.component';
import { TitleComponent } from './components/title/title.component';
import { ImageComparisonSliderComponent } from './components/image-comparison-slider/image-comparison-slider.component';
import { GoogleTourComponent } from './components/google-tour/google-tour.component';
import { GtagModule } from './gtag/gtag.module';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { VideoComponent } from './components/video/video.component';
import { YoutubeGridComponent } from './components/youtube-grid/youtube-grid.component';

@NgModule({
  declarations: [
    AppComponent,
    DefaultPageComponent,
    NavbarComponent,
    FooterComponent,
    ScrollTopComponent,
    KontaktComponent,
    DefaultHeroComponent,
    StoryblokDirective,
    CtaBannerComponent,
    RowImageLeftComponent,
    RowImageTextComponent,
    TypeformHeroComponent,
    TestimonialCarouselComponent,
    RowCardsComponent,
    ImagePipe,
    ErgebnisComponent,
    TextAreaComponent,
    FolderOverviewComponent,
    ContactSectionComponent,
    BlogHeroComponent,
    ProductSectionComponent,
    ShareSectionComponent,
    UpcomingEventsGridComponent,
    EventSectionComponent,
    TitleComponent,
    ImageComparisonSliderComponent,
    GoogleTourComponent,
    VideoComponent, 
    YoutubeGridComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SwiperModule,
    HttpClientModule,
    DynamicModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    ShareModule,
    YouTubePlayerModule,
    GtagModule.init({
      targetId: 'G-GBVV7PVBT7'
    }),
  ],
  providers: [
    StoryblokService,
    { provide: REGION, useValue: 'europe-west1' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule { }
