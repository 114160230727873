import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Client from 'storyblok-js-client';
@Injectable({
  providedIn: 'root'
})
export class StoryblokService {

  private sbClient = new Client({
    accessToken: 'mAQyf6HAXQV3HCgqNbzzdAtt',
    cache: {
      clear: 'auto',
      type: 'memory'
    }
  });

  constructor(
    private http: HttpClient,
  ) { }

  async getStories(params?: any, token: string = 'mAQyf6HAXQV3HCgqNbzzdAtt'): Promise<any> {
    try {
      // const space: any = await this.http.get(`https://api.storyblok.com/v1/cdn/spaces/me?token=${token}`).toPromise();
      const cv = Date.now();
      const res = await this.http.get(`https://api.storyblok.com/v1/cdn/stories?starts_with=${params.query}&is_startpage=0&token=${token}&cv=${cv}`).toPromise();
      return res;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  async getStory(story: string, token: string = 'mAQyf6HAXQV3HCgqNbzzdAtt'): Promise<any> {
    try {
      // const space: any = await this.http.get(`https://api.storyblok.com/v1/cdn/spaces/me?token=${token}`).toPromise();
      const cv = Date.now();
      const res = await this.http.get(`https://api.storyblok.com/v1/cdn/stories/${story}?token=${token}&cv=${cv}`).toPromise();
      return res;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  resolveRichText(richtextField) {
    return this.sbClient.richTextResolver.render(richtextField);
  }
}
