import { Component, Input, OnInit } from '@angular/core';
import { StoryblokService } from 'src/app/storyblok.service';

@Component({
  selector: 'app-folder-overview',
  templateUrl: './folder-overview.component.html',
  styleUrls: ['./folder-overview.component.scss']
})
export class FolderOverviewComponent implements OnInit {
  @Input() blok;
  @Input() slug;
  stories;

  constructor(private storyblokService: StoryblokService) {
  }

  ngOnInit(): void {
    this._fetchBlog();
  }

  resolveRichText(arg) {
    return this.storyblokService.resolveRichText(arg);
  }

  private async _fetchBlog(): Promise<void> {
    try {
      const [{ stories }] = await Promise.all([
        this.storyblokService.getStories({ query: `${this.slug}` })
      ]);

      this.stories = stories;
    } catch (error) {
      console.error(error);
      // TODO: handle UI error
    }
  }

}
