<section class="mx-auto max-w-7xl px-4 sm:px-6 py-12">
  <div class="flex flex-row justify-center">
    <h4 class="text-4xl font-base text-fr-medium-orange font-display">{{ blok.title }}</h4>
  </div>
  <div>
    <swiper
      [slidesPerView]="1"
      [navigation]="true"
      [spaceBetween]="50"
      [speed]="blok.gallery_speed ? +blok.gallery_speed : 3500"
      [autoplay]="{
        delay: blok.gallery_delay ? +blok.gallery_delay : 10000
      }"
    >
      <ng-template swiperSlide *ngFor="let testimonial of blok.testimonials">
        <div class="max-w-xl flex flex-col items-center justify-center mx-auto py-4 space-y-3">
          <p class="font-body text-base text-center font-normal leading-7">
            {{ testimonial.text }}
          </p>
          <div class="flex flex-row space-x-4 items-center">
            <picture>
              <source type="image/webp" [srcset]="testimonial?.image?.filename | transformImg : 'fit-in/0x100/smart/filters:format(webp)'">
              <source type="image/png" [srcset]="testimonial?.image?.filename | transformImg : 'fit-in/0x100/smart/filters:format(png)'">
              <img loading="lazy" class="inline-block h-6 w-6 rounded-full" [src]="testimonial?.image?.filename | transformImg : 'fit-in/0x100/smart'" [alt]="testimonial?.image?.alt">
            </picture>
            <span *ngIf="testimonial?.title" class="text-sm font-medium font-body">{{ testimonial.title }}</span>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
</section>