import { Pipe } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({name: 'transformImg'})
export class ImagePipe {
  constructor(){}

  transform(image: string, option?: string): any {
    console.log(image);
    console.log(option);

    if (!image) return '';
    if (!option) return '';

    console.log(image);
    console.log(option);

    let imageService = 'https://img2.storyblok.com/';
    let path = image.replace('https://a.storyblok.com', '');
    console.log(imageService + option + path);
    return imageService + option + path;
  }
}