import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-youtube-grid',
  templateUrl: './youtube-grid.component.html'
})
export class YoutubeGridComponent implements OnInit {
  @Input() blok: any;
  constructor() { }

  ngOnInit(): void {
  }

}
